<template>
  <v-app>
    <div class="ui-pedidos">
      <ul class="pl-0 mt-3">
        <li v-for="(order, ind) in orders" :key="ind" class="mb-5">
          <v-row justify="space-between" class="mb-3 mb-md-0 mt-2">
            <v-col cols="12" md="8" class="py-0">
              <div
                class="text mb-2"
                :class="
                  $vuetify.breakpoint.width < 500 ? 'text-center' : 'text-left'
                "
              >
                <b>Pedido #{{ order.id }} | </b>
                {{ order.date }}
                <span v-if="$vuetify.breakpoint.width >= 500"> - </span>
                <br v-if="$vuetify.breakpoint.width < 500" />
                <v-chip
                  rounded
                  small
                  class="my-0"
                  :text-color="order.step === '2' ? '#000' : '#fff'"
                  :color="getStatusColor(order.step)"
                  dark
                  >{{ statusName(order.step) }}</v-chip
                >
              </div>
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <div>
                <div
                  class="text mb-2"
                  :class="
                    $vuetify.breakpoint.width < 500
                      ? 'text-center'
                      : 'text-right'
                  "
                >
                  <v-chip
                    label
                    class="my-0 mr-2 mb-2 mb-md-0"
                    color="success"
                    dark
                    v-if="order.step == 2 && order.payment_method == 1"
                    @click="getQrcode(order)"
                    >Realizar pagamento</v-chip
                  >
                  <b>Total: </b>{{ FormatValue(order.total) }}
                </div>
              </div>
            </v-col>
          </v-row>
          <!-- <div> -->
          <!-- <div
              class="produto position-relative"
              v-for="(product, index) in order.products"
              :key="index"
              :class="
                index === order.products.length - 1 ? 'ultimo_produto' : ''
              "
            >
              <v-btn
                v-if="order.step == 3"
                class="product-qrcode-button"
                color="#e4e4e4"
                fab
                elevation="1.5"
                width="35"
                height="35"
                absolute
                @click="openProductDetailsModal(product.id)"
              >
                <v-icon>mdi mdi-qrcode</v-icon>
              </v-btn>
              <div class="text">
                <b> {{ product.product.name }} </b>
                <span class="numeracao-pedido">Nº{{ index + 1 }}</span>
              </div>
              <button
                v-if="order.step == 2"
                class="mt-1 border_button"
                @click="openObservationDialog(product, index, ind)"
              >
                Editar observação
              </button>
              <div class="text text-border">
                Observações: {{ product.observation }}
              </div>
              <div class="row align-items-center justify-content-between mt-2">
                <div class="col-6 text"></div>
                <div class="col-6 text cor_primaria text-right">
                  <b>{{ FormatValue(product.value) }}</b>
                </div>
              </div>
            </div> -->
          <div class="ui-lista overflow_carrinho mt-2">
            <ul class="row pl-0">
              <li
                class="col-md-6 col-12"
                v-for="(product, index) in order.products"
                :key="index"
              >
                <div class="ui-lista-area">
                  <div class="row">
                    <div
                      class="col-6 pl-0"
                      v-if="product && product.product.image"
                    >
                      <div
                        class="ui-show-image-area"
                        :style="
                          'background-image: url(' + product.product.image + ')'
                        "
                      >
                        <img
                          :src="product.product.image"
                          :alt="product.product.image"
                        />
                      </div>
                    </div>
                    <div
                      :class="
                        product.product.image ? 'col-6 pl-0' : 'col-12 pl-2'
                      "
                    >
                      <div
                        class="ui-lista-conteudo ui-relative pt-7 pb-5"
                        :class="product.product.image ? '' : 'pl-3'"
                      >
                        <v-btn
                          v-if="order.step == 3"
                          class="product-qrcode-button"
                          color="#e4e4e4"
                          fab
                          elevation="1.5"
                          width="35"
                          height="35"
                          absolute
                          @click="openProductDetailsModal(product.id)"
                        >
                          <v-icon>mdi mdi-qrcode</v-icon>
                        </v-btn>
                        <div
                          class="edita_pedido"
                          v-if="order.step == 2"
                          @click="openObservationDialog(product, index, ind)"
                        >
                          {{ product.observation ? "Editar" : "Criar" }}
                          observação <i class="fa fa-edit"></i>
                        </div>
                        <div class="ui-lista-titulo text-left">
                          {{ product.product.name }}
                        </div>
                        <div
                          class="text"
                          v-html="product.product.description"
                        ></div>
                        <div class="ui-lista-quantidade-row">
                          <div class="ui-lista-valor text-left cor_primaria">
                            {{ FormatValue(product.value) }}
                          </div>
                        </div>
                        <div
                          class="ui-text-small mt-3 mb-10"
                          v-if="product.observation"
                        >
                          <b>Observação:</b> {{ product.observation }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <!-- </div> -->
          <v-divider class="mt-5" v-if="ind + 1 < orders.length"></v-divider>
        </li>
      </ul>
    </div>

    <!-- MODAL DE EDIÇÃO DE OBSERVAÇÕES -->
    <v-dialog v-model="dialog" persistent max-width="300px">
      <v-card class="ui-dialog-top">
        <div class="ml-0 mr-0 row align-items-center justify-space-between">
          <div class="col-8 pl-0">
            <div class="title_modal">Editar observação</div>
          </div>
          <div class="col-4 text-right pr-0">
            <i
              class="fa fa-close cor_primaria cursor-pointer"
              @click="dialog = false"
            ></i>
          </div>
        </div>
      </v-card>
      <v-card class="ui-dialog-content">
        <v-form ref="formObservation">
          <v-textarea
            outlined
            no-resize
            rows="3"
            v-model="observation_data.text"
            label="Observação"
            hide-details=""
          ></v-textarea>
        </v-form>
        <v-divider></v-divider>
        <div class="row ml-0 mr-0">
          <div class="col-6 pl-0">
            <button
              class="ui-button cancel"
              :disabled="observation_loading"
              @click="dialog = false"
            >
              Cancelar
            </button>
          </div>
          <div class="col-6 text-right pr-0">
            <button
              class="ui-button fundo_secundaria save-observation"
              :disabled="observation_loading"
              @click="SaveObservation"
            >
              <i v-if="observation_loading" class="fa fa-spinner fa-spin"></i>
              <span v-else> Editar </span>
            </button>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- MODAL DE QRCODE DO PIX -->
    <v-dialog
      v-model="qrcode.dialog"
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card class="ui-dialog-content">
        <div class="row align-items-center">
          <div class="col-12">
            <div class="row pix--logo">
              <div class="col-4">
                <v-img contain src="img/website/logo_pix.svg"></v-img>
              </div>
              <div class="col-2 pix--subtitle--intern">PIX</div>
              <div class="col-6 text-end align-self-baseline">
                <v-icon @click="qrcode.dialog = false" right
                  >mdi mdi-close</v-icon
                >
              </div>
            </div>
            <div class="pix--description">
              <span>Instruções:</span>
              <div>
                1 - Leia ou copie o código do QRCODE PIX.<br />
                2 - No banco de sua preferência efetue o pagamento.<br />
                3 - Na tela “Meus pedidos”, aguarde a confirmação do pagamento.
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="col-12 d-flex justify-content-center pb-0">
              <div
                v-html="qrcode.base64"
                @click="CopyQRCODE"
                class="pix--QRCODE cursor-pointer"
              ></div>
            </div>
            <div class="col-12 text-center pt-0 pix--QRCODE--subtitle">
              Clique no QRCODE para copiá-lo
              <v-icon>mdi mdi-content-copy</v-icon>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- MODAL DE QRCODE DO PRODUTO -->
    <v-dialog
      v-model="product_details.dialog"
      transition="dialog-transition"
      width="auto"
    >
      <v-card class="w-fit">
        <v-col class="d-flex justify-content-center">
          <qr-code :text="product_details.qrcode"></qr-code>
        </v-col>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import axios from "axios";
import VueQRCodeComponent from "vue-qrcode-component";
const currency = require("currency.js");
Vue.component("qr-code", VueQRCodeComponent);

export default {
  props: [
    "ordersList",
    "observation_endpoint",
    "qrcode_endpoint",
    "product_detail_url",
  ],
  data() {
    return {
      dialog: false,
      orders: [],
      observation_loading: false,
      product_details: {
        dialog: false,
        qrcode: "",
      },
      qrcode: {
        link: "",
        base64: "",
        dialog: false,
      },
      observation_data: {
        id: null,
        text: "",
        number: null,
        cart: null,
      },
    };
  },

  created() {
    if (this.ordersList.length > 0) {
      this.orders = JSON.parse(this.ordersList);
    }
  },

  methods: {
    openObservationDialog(product, index, cartIndex) {
      this.observation_data.id = product.id;
      this.observation_data.text = product.observation;
      this.observation_data.number = index;
      this.observation_data.cart = cartIndex;
      this.dialog = true;
    },

    async SaveObservation() {
      this.observation_loading = true;
      await axios
        .post(this.observation_endpoint, this.observation_data, {
          withCredentials: true,
        })
        .then((res) => {
          this.$swal({
            title: res.data.message,
            type: "success",
            timer: 1200,
          });

          this.orders[this.observation_data.cart].products[
            this.observation_data.number
          ].observation = this.observation_data.text;
        })
        .catch((err) => {
          console.error(err);
        });
      this.observation_loading = false;
      this.dialog = false;
    },

    FormatValue(value) {
      const price = Number(value);
      return currency(price, {
        separator: ".",
        decimal: ",",
        symbol: "R$",
      }).format();
    },

    statusName(step) {
      switch (+step) {
        case 1:
          return "Escolhendo Produtos";
        case 2:
          return "Aguardando Pagamento";
        case 3:
          return "Pagamento Confirmado";
        case 4:
          return "Pedido Cancelado";
        case 5:
          return "Pedido Cancelado Por Ociosidade";
        case 6:
          return "Pedido Cancelado Pela PaySell";
      }
    },

    getStatusColor(step) {
      switch (+step) {
        case 1:
          return "warning";
        case 2:
          return "yellow";
        case 3:
          return "success";
        case 4:
          return "error";
        case 6:
          return "error";
        default:
          return "gray";
      }
    },

    async getQrcode(order) {
      this.$swal({
        title:
          "<span style='line-height:normal'>Gerando QRCODE, aguarde...</span>",
        allowOutsideClick: false,
        onOpen: () => {
          this.$swal.showLoading();
        },
      });

      await axios
        .post(this.qrcode_endpoint, { id: order.id, link: order.qrcode_link })
        .then((res) => {
          this.qrcode.dialog = true;
          this.qrcode.link = order.qrcode_link;
          this.qrcode.base64 = res.data.qrcode;
        })
        .catch((err) => {
          this.$swal(
            "Oops!",
            "Ocorreu algo inesperado, recarregue a página e tente novamente.",
            "error"
          );
          console.error(err);
        });
      this.$swal.close();
    },

    CopyQRCODE() {
      navigator.clipboard.writeText(this.qrcode.base64);
      Toast.fire("Link Copiado!", "", "success");
    },

    openProductDetailsModal(productID) {
      this.product_details = {
        dialog: true,
        qrcode: this.product_detail_url.replace(/productID/g, productID),
      };
    },
  },
};
</script>



<style lang="scss" scoped>
.pix {
  &--subtitle {
    padding-top: unset;
    font-weight: 300;
    line-height: 15px;
    color: #4a4a4a;
    font-size: 16px;
    margin-bottom: 22px;
    @media screen and (max-width: 991px) {
      font-size: 13px;
    }

    &--intern {
      @extend .pix--subtitle;
      padding-top: 12px;
      padding-left: unset;
      margin-bottom: unset;
      align-self: center;
    }
  }

  &--description {
    margin-top: 35px;
    color: #4a4a4a;
    & span:first-child {
      @extend .pix--description;
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
    }

    & div {
      margin-top: 11px;
      font-weight: 300;
      font-size: 17px;
      line-height: 34px;
    }
  }

  &--QRCODE {
    max-height: 350px;
    max-width: 350px;
    @media screen and (max-width: 425px) {
      max-height: 250px;
      max-width: 250px;
    }

    &--subtitle {
      font-weight: 300;
      font-size: 17px;
      line-height: 34px;
    }
  }
}
.pedido {
  background: #ffffff;
  border-radius: 10px;
  padding: 10px 10px 0px 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
}
.text-border {
  border: 1px #a3a1a1 solid;
  border-radius: 10px;
  padding: 10px;
  margin-top: 15px;
  width: 100%;
}
.produto {
  border-bottom: 1px #dbdbdb solid;
  padding-bottom: 10px;
  padding-top: 10px;
}
.ultimo_produto {
  border: none !important;
}
.border_button {
  border-radius: 5px;
  padding: 5px;
  border: 1px rgba(0, 0, 0, 0.23) solid;
}
.cancel {
  background: #4a4a4a;
}
.total_area {
  border-top: 1px solid #eaeaea;
}
.title_modal {
  font-size: 18px;
  line-height: normal;
  font-weight: bold;
}

.save-observation {
  min-width: 77px;
}

.product-qrcode-button {
  bottom: 10px;
  right: 10px;
}

.w-fit {
  width: fit-content !important;
}

.numeracao-pedido {
  font-size: 11px;
  vertical-align: super;
  font-weight: bolder;
  color: #b1b1b1;
}
.edita_pedido {
  cursor: pointer;
  color: #7d7d7d;
  font-size: 12px;
  position: absolute;
  right: 5px;
  top: 5px;
}
</style>