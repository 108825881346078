<template>
  <div>
    <v-form ref="form" @submit.prevent="recoverPassword">
      <v-text-field
        v-model="esqueceu.email"
        label="E-mail*"
        type="email"
        :rules="[rules.required]"
        :error-messages="custom_errors.email"
        outlined
        :disabled="salvando"
        :loading="salvando"
        class="mt-2"
      ></v-text-field>
      <button class="ui-button fundo_secundaria w-100" :disabled="salvando">
        <i v-if="salvando" class="fa fa-spinner fa-spin"></i>
        <span v-else> Enviar </span>
      </button>
    </v-form>
    <a href="/cadastro">
      <div class="ui-text cor_primaria mt-4 text-center">
        Não tem uma conta? Cadastre-se
      </div>
    </a>
    <a href="/login">
      <div class="ui-text mt-3 text-center">
        Já possui cadastro? Acesse o Login
      </div>
    </a>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["recover_password_endpoint", "login_url"],
  data() {
    return {
      salvando: false,
      rules: {
        required: (value) => !!value || "Obrigatório.",
        min: (v) => v.length >= 6 || "Mínimo 6 caracteres",
      },
      esqueceu: {
        email: "",
      },
      custom_errors: {
        email: "",
      },
    };
  },
  methods: {
    async recoverPassword() {
      this.salvando = true;
      this.custom_errors.email = "";
      if (this.$refs.form.validate()) {
        await axios
          .post(this.recover_password_endpoint, this.esqueceu)
          .then((res) => {
            console.log(res);
            this.$swal({
              titleText: "E-mail de recuperação enviado!",
              html: res.data.message,
              type: "success",
            }).then(() => {
              location.href = this.login_url;
            });
          })
          .catch((err) => {
            let errors = err?.response?.data?.errors;
            if (errors) {
              for (const key in errors) {
                if (Object.hasOwnProperty.call(errors, key)) {
                  this.custom_errors[key] = errors[key];
                }
              }
            }
          });
      }
      this.salvando = false;
    },
  },
};
</script>


<style lang="scss" scoped>
.botao_senha {
  cursor: pointer;
  font-size: 18px;
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>