

window.$ = window.jQuery = require("jquery");

require("bootstrap");

window.Vue = require("vue");

import Vue from "vue";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
Vue.use(VueSweetalert2);

const Toast = Swal.mixin({
  toast: true,
  position: "top",
  timer: 1500,
  showConfirmButton: true
});
window.Toast = Toast;

Vue.component("carrinho-lista", require("./CarrinhoLista.vue").default);
Vue.component("the-payment", require("./ThePayment").default);
Vue.component("the-produto", require("./TheProduto.vue").default);
Vue.component("the-login", require("./TheLogin.vue").default);
Vue.component("esqueceu-senha", require("./EsqueceuSenha.vue").default);
Vue.component("redefinir-senha", require("./RedefinirSenha.vue").default);
Vue.component("the-cadastro", require("./TheCadastro.vue").default);
Vue.component("the-pedidos", require("./ThePedidos.vue").default);
Vue.component("produtos-lista", require("./ProdutosLista.vue").default);

$(function () {
  $(".menu__box").click(function () {
    var button = document.getElementById('menu__toggle')
    button.checked = !button.checked
    // if (button.checked) {
    // 	$(".footer_menu").addClass("fundo_primaria")
    // 	$(".span_menu").removeClass("d-none")
    // 	$(".span_menu").removeClass("cor_primaria")
    // 	$(".icone_menu_footer").addClass("cor_icone_footer")
    // 	$(".icone_menu_footer").removeClass("cor_primaria")
    // } else {
    // 	$(".footer_menu").removeClass("fundo_primaria")
    // 	$(".span_menu").addClass("d-none")
    // 	$(".icone_menu_footer").removeClass("cor_icone_footer")
    // 	$(".icone_menu_footer").addClass("cor_primaria")
    // }
  });
  // $(document).click(function (e) {
  // 	var button = document.getElementById('carrinho__toggle')
  // 	if (document.getElementById('carrinho_area').contains(e.target)) {
  // 		// button.checked = true;
  // 		// console.log('clicou dentro', button.checked)
  // 	} else {
  // 		// console.log('clicou fora' , button.checked)
  // 		// button.checked = false
  // 	}
  // });

  $(document).click(function (event) {
    const menu = document.getElementById('menu__toggle')
    const cart = document.getElementById('carrinho__toggle')
    const cart_icon = document.getElementsByClassName('icone_carrinho')[0];
    const menu_icon = document.getElementsByClassName('icone_menu_header')[0];
    if (
      event.target != menu &&
      event.target != cart &&
      event.target != menu_icon &&
      event.target != cart_icon &&
      !$('#carrinho_area').find(event.target).length &&
      !$('#menu-box').find(event.target).length &&
      !$('.v-dialog').find(event.target).length &&
      event.target.id != 'menu-box' &&
      event.target.id != 'carrinho_area' &&
      !event.target.classList.contains('icone_carrinho_footer') &&
      event.target.id != 'btn_menu'
    ) {
      menu?.classList?.remove('menu--active')
      cart?.classList?.remove('cart--active')
    }
    if (event.target.id === 'carrinho__toggle') {
      cart?.classList?.remove('cart--active')
    }
  });

  $(".icone_carrinho").click(function (event) {
    document.getElementsByClassName('menu--active')[0]?.classList?.remove('menu--active')
    var button = document.getElementById('carrinho__toggle')
    if (button.classList.contains('cart--active')) {
      button.classList.remove('cart--active')
    } else {
      button.classList.add('cart--active')
    }
  });

  $(".icone_menu_header").click(function (event) {
    document.getElementsByClassName('cart--active')[0]?.classList?.remove('cart--active')
    var button = document.getElementById('menu__toggle')
    if (button.classList.contains('menu--active')) {
      button.classList.remove('menu--active')
    } else {
      button.classList.add('menu--active')
    }
  });

  $(".icone_carrinho_footer").click(function (event) {
    document.getElementsByClassName('menu--active')[0]?.classList?.remove('menu--active')
    var button = document.getElementById('carrinho__toggle')
    if (button.classList.contains('cart--active')) {
      button.classList.remove('cart--active')
    } else {
      button.classList.add('cart--active')
      const profile = document.querySelector('.footer_mobile_content>.fundo_primaria').classList;
      profile?.remove('fundo_primaria')
      document.querySelectorAll('.footer_mobile_content>a:not(.fundo_primaria)>.fa').forEach(item => item.classList.add('cor_primaria'));
      document.querySelectorAll('.footer_mobile_content>a:not(.footer_carrinho)>span').forEach(item => item.style.display = 'none');
    }
  });


  $(".footer_carrinho").click(function () {
    var botao = document.getElementById('carrinho__toggle')
    if (botao) {
      botao.checked = !botao.checked
      if (botao.checked) {
        $(".footer_carrinho").addClass("fundo_primaria")
        $(".span_carrinho").removeClass("d-none")
        $(".span_carrinho").removeClass("cor_primaria")
        $(".icone_carrinho_footer").addClass("cor_icone_footer")
        $(".icone_carrinho_footer").removeClass("cor_primaria")
      } else {
        $(".footer_carrinho").removeClass("fundo_primaria")
        $(".span_carrinho").addClass("d-none")
        $(".icone_carrinho_footer").removeClass("cor_icone_footer")
        $(".icone_carrinho_footer").addClass("cor_primaria")
      }
    }
  });
  $(".carrinho__btn").click(function () {
    $(".footer_carrinho").removeClass("fundo_primaria")
    $(".span_carrinho").addClass("d-none")
    $(".icone_carrinho_footer").removeClass("cor_icone_footer")
    $(".icone_carrinho_footer").addClass("cor_primaria")
  });
  $(".footer_menu").click(function () {
    var botao = document.getElementById('menu__toggle')
    botao.checked = !botao.checked
    if (botao.checked) {
      $(".footer_menu").addClass("fundo_primaria")
      $(".span_menu").removeClass("d-none")
      $(".span_menu").removeClass("cor_primaria")
      $(".icone_menu_footer").addClass("cor_icone_footer")
      $(".icone_menu_footer").removeClass("cor_primaria")
    } else {
      $(".footer_menu").removeClass("fundo_primaria")
      $(".span_menu").addClass("d-none")
      $(".icone_menu_footer").removeClass("cor_icone_footer")
      $(".icone_menu_footer").addClass("cor_primaria")
    }
  });
  $("#btn_menu").click(function () {
    // document.getElementsByClassName('cart--active')[0]?.classList?.remove('cart--active')
    var button = document.getElementById('menu__toggle')
    if (button.classList.contains('menu--active')) {
      button.classList.remove('menu--active')
    } else {
      button.classList.add('menu--active')
      $(".footer_carrinho").removeClass("fundo_primaria")
      $(".span_carrinho").addClass("d-none")
      $(".icone_carrinho_footer").removeClass("cor_icone_footer")
      $(".icone_carrinho_footer").addClass("cor_primaria")
    }
  });
});

import Vuetify from 'vuetify';
Vue.use(Vuetify);

const app = new Vue({
  el: "#app",
  vuetify: new Vuetify(),
});