<template>
  <v-app>
    <v-main>
      <v-sheet class="mb-5 login-error-sheet">{{ error_msg }}</v-sheet>
      <form>
        <v-text-field
          v-model="login.usuario"
          label="E-mail*"
          :rules="[rules.required, rules.email]"
          outlined
          :disabled="salvando"
          :loading="salvando"
          class="mb-3"
        ></v-text-field>
        <v-text-field
          @click:append="show = !show"
          outlined
          label="Senha*"
          :loading="salvando"
          :disabled="salvando"
          :rules="[rules.required]"
          :type="show ? 'text' : 'password'"
          v-model="login.senha"
          :append-icon="show ? 'fa-eye-slash' : 'fa-eye'"
        ></v-text-field>
        <button
          class="ui-button fundo_secundaria w-100"
          @click="Login()"
          :disabled="salvando"
        >
          <i v-if="salvando" class="fa fa-spinner fa-spin"></i>
          <span v-else> Entrar </span>
        </button>
      </form>
      <a href="/cadastro">
        <div class="ui-text cor_primaria mt-4 text-center">
          Não tem uma conta? Cadastre-se
        </div>
      </a>
      <a href="/recuperar-senha">
        <div class="ui-text mt-3 text-center">
          Perdeu a senha? Recuperar senha
        </div>
      </a>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  props: ["login_endpoint", "home_url", "add_product_endpoint"],
  data() {
    return {
      show: false,
      salvando: false,
      rules: {
        required: (value) => !!value || "Obrigatório.",
        min: (v) => v.length >= 6 || "Mínimo 6 caracteres",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "E-mail inválido.";
        },
      },
      error_msg: "",
      login: {
        usuario: "",
        senha: "",
      },
    };
  },

  methods: {
    async Login() {
      this.salvando = true;
      await axios
        .post(this.login_endpoint, {
          email: this.login.usuario,
          password: this.login.senha,
        })
        .then(async (res) => {
          document.cookie = `FrontJwt=${res.data.token};`;
          await this.putProductsOnCart();
          location.href = this.home_url;
        })
        .catch((err) => {
          this.error_msg = err.response.data.error;

          this.salvando = false;
        });
    },
    async putProductsOnCart() {
      const products = JSON.parse(localStorage.getItem("products"));
      await axios
        .post(this.add_product_endpoint, products, { withCredentials: true })
        .catch((err) => {
          console.error(err);
        });
      localStorage.removeItem("products");
    },
  },
};
</script>


<style lang="scss" scoped>
.botao_senha {
  cursor: pointer;
  font-size: 18px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.login-error-sheet {
  font-size: 14px;
  line-height: 18px;
  color: #ee1d23 !important;
}

::v-deep .v-application--wrap {
  min-height: fit-content;
}
</style>