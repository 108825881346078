<template>
  <v-app>
    <div v-if="cart && cart.products && cart.products.length > 0">
      <div class="ui-lista overflow_carrinho mt-2">
        <ul class="row pl-2">
          <li
            class="col-12"
            v-for="(item, index) in cart.products"
            :key="index"
          >
            <div class="ui-lista-area height_carrinho position-relative">
              <div class="numeracao-carrinho">
                <div
                  class="edita_pedido"
                  @click="openObservationDialog(item, index, cart.id)"
                >
                  {{ item.observation ? "Editar" : "Criar" }} observação
                  <i class="fa fa-edit"></i>
                </div>
              </div>
              <div class="excluir_produto">
                <v-btn
                  :x-small="$vuetify.breakpoint.smAndDown"
                  icon
                  color="error"
                  @click="removeProduct(item.id, index)"
                >
                  <v-icon>mdi mdi-delete</v-icon>
                </v-btn>
              </div>
              <div class="row">
                <div class="col-6 pl-0">
                  <div
                    class="ui-show-image-area hover-cart-product"
                    :style="'background-image: url(' + item.product.image + ')'"
                    @click="openProductUrl(item)"
                  >
                    <img :src="item.product.image" :alt="item.product.name" />
                  </div>
                </div>
                <div class="col-6 pl-0">
                  <div class="ui-lista-conteudo">
                    <div class="ui-lista-titulo text-left pt-2">
                      {{ item.product.name }}
                    </div>
                    <div class="ui-lista-quantidade-row">
                      <div class="ui-lista-valor text-left cor_primaria">
                        {{ FormatValue(item.value) || "0,00" }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="observacao text-left"
              v-if="item.observation"
              :key="item.observation"
            >
              <span> Observação: {{ item.observation }}</span>
              <!-- <v-tooltip
                transition="slide-x-reverse-transition"
                content-class="observation-tooltip"
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    Observação: {{ item.observation }}</span
                  >
                </template>
                Editar observação
              </v-tooltip> -->
            </div>
            <!-- <div
              v-else
              class="observacao"
              @click="OpenObservation(item.id, index, item.observation)"
            >
              <v-tooltip
                transition="slide-x-reverse-transition"
                content-class="observation-tooltip"
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="primary"
                    rounded
                    small
                    v-bind="attrs"
                    v-on="on"
                    >Observação <v-icon right>mdi mdi-plus</v-icon></v-btn
                  >
                </template>
                Nova observação
              </v-tooltip>
            </div> -->
          </li>
        </ul>
      </div>
      <div class="total cor_primaria">
        <div>Total</div>
        <div>{{ FormatValue(cart.total) || "0,00" }}</div>
      </div>
      <div class="enviar">
        <button @click="toPayment" class="ui-button-green fundo_secundaria">
          Enviar pedido
        </button>
      </div>
      <div class="esvaziar">
        <span @click="clearCart">Esvaziar carrinho</span>
      </div>
      <v-dialog v-model="observation_modal" persistent max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline"
              >Observação do Produto Nº{{ observation_data.number + 1 }}</span
            >
          </v-card-title>
          <v-card-text>
            <v-textarea
              clear-icon="mdi-close-circle"
              auto-grow
              rows="1"
              row-height="15"
              autofocus
              :maxlength="150"
              counter="150"
              clearable
              name="observation"
              :rules="[rules.length(150)]"
              v-model="observation_data.text"
              filled
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <button
              class="btn btn-flat btn-secondary"
              type="button"
              @click="observation_modal = false"
            >
              Fechar
            </button>
            <v-spacer></v-spacer>
            <button
              class="btn btn-flat btn-success"
              type="button"
              @click="SaveObservation"
            >
              Salvar
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div v-else class="carrinho_vazio">
      <div>
        <img :src="require('/public/img/website/carrinho_vazio.png')" />
        <div class="w-100 mt-1">Seu carrinho está vazio</div>
      </div>
    </div>

    <!-- MODAL DE EDIÇÃO DE OBSERVAÇÕES -->
    <v-dialog v-model="dialog" persistent max-width="300px">
      <v-card class="ui-dialog-top">
        <div class="ml-0 mr-0 row align-items-center justify-space-between">
          <div class="col-8 pl-0">
            <div class="title_modal">Editar observação</div>
          </div>
          <div class="col-4 text-right pr-0">
            <i
              class="fa fa-close cor_primaria cursor-pointer"
              @click="dialog = false"
            ></i>
          </div>
        </div>
      </v-card>
      <v-card class="ui-dialog-content">
        <v-form ref="formObservation">
          <v-textarea
            outlined
            no-resize
            rows="3"
            v-model="observation_data.text"
            label="Observação"
            hide-details=""
          ></v-textarea>
        </v-form>
        <v-divider></v-divider>
        <div class="row ml-0 mr-0">
          <div class="col-6 pl-0">
            <button
              class="ui-button cancel"
              :disabled="observation_loading"
              @click="dialog = false"
            >
              Cancelar
            </button>
          </div>
          <div class="col-6 text-right pr-0">
            <button
              class="ui-button fundo_secundaria save-observation"
              :disabled="observation_loading"
              @click="SaveObservation"
            >
              <i v-if="observation_loading" class="fa fa-spinner fa-spin"></i>
              <span v-else> Editar </span>
            </button>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import axios from "axios";
const currency = require("currency.js");
export default {
  props: [
    "cart_data",
    "observation_endpoint",
    "remove_product_endpoint",
    "payment_url",
    "clear_cart_endpoint",
  ],
  data() {
    return {
      cart: [],
      observation_modal: false,
      observation_data: {
        id: null,
        number: "",
        text: "",
      },
      observation_loading: false,
      rules: {
        length: (len) => (v) =>
          (v || "").length <= len ||
          `O tamanho máximo da observação é de ${len} caracteres.`,
      },
      observation_data: {
        id: null,
        text: "",
        number: null,
        cart: null,
      },
      dialog: false,
    };
  },
  created() {
    if (this.cart_data) {
      this.cart = JSON.parse(this.cart_data);
    }
  },

  methods: {
    FormatValue(value) {
      const price = Number(value);
      return currency(price, {
        separator: ".",
        decimal: ",",
        symbol: "R$",
      }).format();
    },

    OpenObservation(id, index, observation) {
      this.observation_data = {
        id: id,
        number: index,
        text: observation,
      };
      this.observation_modal = true;
    },

    removeProduct(id, index) {
      this.$swal({
        titleText: `Remover Produto Nº${index + 1}?`,
        html: "Ao remover o produto, a <b>observação</b> do mesmo será <b>perdida</b>.",
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonColor: "#ff5252",
        cancelButtonText: "Não!",
        cancelButtonColor: "primary",
        confirmButtonText: "Sim, remover!",
        type: "warning",
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.$swal({
            titleText: "Removendo produto, aguarde...",
            allowOutsideClick: false,
            onOpen: () => {
              this.$swal.showLoading();
            },
          });
          axios
            .post(
              this.remove_product_endpoint,
              { id },
              { withCredentials: true }
            )
            .then((res) => {
              let removed = this.cart.products.splice(index, 1);
              this.cart.total -= removed[0].value;

              //bloco de código para subtrair a badge sobre o icone do carrinho
              let $badge = $(".quantidade_carrinho--badge");
              $badge.html($badge.html() - 1);
              if ($badge.html() == 0) {
                $(".quantidade_carrinho--wrapper").remove();
              }

              let $badge_mobile = $(".quantidade_carrinho--badge_mobile");
              $badge_mobile.html($badge_mobile.html() - 1);
              if ($badge_mobile.html() == 0) {
                $(".quantidade_carrinho--wrapper_mobile").remove();
              }

              this.$swal({
                titleText: res.data.message,
                timer: 1200,
                type: "success",
              });
            })
            .catch((err) => {
              console.error(err);
            });
        }
      });
    },

    clearCart() {
      this.$swal({
        titleText: `Esvaziar carrinho?`,
        html: "Ao esvaziar o carrinho, as <b>observações</b> dos produtos serão <b>perdidas</b>.",
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonColor: "#ff5252",
        cancelButtonText: "Não!",
        cancelButtonColor: "primary",
        confirmButtonText: "Sim, remover!",
        type: "warning",
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.$swal({
            titleText: "Esvaziando carrinho, aguarde...",
            allowOutsideClick: false,
            onOpen: () => {
              this.$swal.showLoading();
            },
          });
          axios
            .post(
              this.clear_cart_endpoint,
              { id: this.cart.id },
              { withCredentials: true }
            )
            .then((res) => {
              this.$swal({
                titleText: res.data.message,
                timer: 1200,
                type: "success",
              }).then(() => {
                location.reload();
              });
            })
            .catch((err) => {
              console.error(err);
            });
        }
      });
    },

    async SaveObservation() {
      this.observation_loading = true;
      await axios
        .post(this.observation_endpoint, this.observation_data, {
          withCredentials: true,
        })
        .then((res) => {
          this.$swal({
            title: res.data.message,
            type: "success",
            timer: 1200,
          });

          this.cart.products[this.observation_data.number].observation =
            this.observation_data.text;
        })
        .catch((err) => {
          console.error(err);
        });
      this.observation_loading = false;
      this.observation_modal = false;
      this.dialog = false;
    },

    openProductUrl(item) {
      let url = location.origin + "/produto/" + item.product.slug;
      location.href = url;
    },

    toPayment() {
      location.href = this.payment_url;
    },

    openObservationDialog(product, index, cartIndex) {
      this.observation_data.id = product.id;
      this.observation_data.text = product.observation;
      this.observation_data.number = index;
      this.observation_data.cart = cartIndex;
      this.dialog = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.overflow_carrinho {
  padding: 5px 15px 0 5px;
  overflow: auto;
  height: calc(100vh - 250px);
  @media screen and (max-width: 991px) {
    height: calc(100vh - 280px);
  }
}
.total {
  align-items: center;
  border-top: 1px #d9d9d9 solid;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  padding-top: 10px;
}

.numeracao-carrinho {
  position: absolute;
  right: 5px;
  z-index: 1;
  font-size: 12px;
  font-weight: bolder;
  color: #b1b1b1;
}

.excluir_produto {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
}

.ui-lista-area:hover {
  opacity: unset;
  transition: unset;
}

.hover-cart-product:hover {
  opacity: 0.8;
  transition: all 0.3s;
  cursor: pointer;
}

.observation-tooltip {
  background: #1976d2 !important;
}

.observation-text-hover:hover {
  color: #1976d2;
  transition: all 0.3s;
}

.esvaziar {
  text-align: center;
  font-size: 13px;
  margin-top: 5px;
  text-decoration: underline;
  color: #d04242;

  & span:hover {
    cursor: pointer;
    color: #ff7373 !important;
    transition: all 0.3s;
  }
}
</style>