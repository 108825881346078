<template>
  <div>
    <v-form ref="form" @submit.prevent="changePassword">
      <div>
        <v-text-field
          @click:append="show.password = !show.password"
          v-model="recuperar.password"
          label="Senha*"
          :rules="[rules.required, rules.min]"
          :error-messages="custom_errors.password"
          outlined
          :disabled="salvando"
          :loading="salvando"
          :type="show.password ? 'text' : 'password'"
          :append-icon="show.password ? 'fa-eye-slash' : 'fa-eye'"
        ></v-text-field>
      </div>
      <div>
        <v-text-field
          @click:append="show.repita_password = !show.repita_password"
          v-model="recuperar.repita_password"
          label="Repita Senha*"
          :rules="[rules.required, rules.equal]"
          :error-messages="custom_errors.repita_password"
          outlined
          :disabled="salvando"
          :loading="salvando"
          :type="show.repita_password ? 'text' : 'password'"
          :append-icon="show.repita_password ? 'fa-eye-slash' : 'fa-eye'"
        ></v-text-field>
      </div>
      <button class="ui-button fundo_secundaria w-100" :disabled="salvando">
        <i v-if="salvando" class="fa fa-spinner fa-spin"></i>
        <span v-else> Redefinir </span>
      </button>
    </v-form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["change_password_endpoint", "login_url", "recovery_token"],
  data() {
    return {
      salvando: false,
      rules: {
        required: (value) => !!value || "Obrigatório.",
        min: (v) => v.length >= 6 || "Mínimo 6 caracteres",
        equal: (v) =>
          v == this.recuperar.password || "As senhas devem ser iguais.",
      },
      recuperar: {
        password: "",
        repita_password: "",
        token: this.recovery_token,
      },
      custom_errors: {
        password: "",
        repita_password: "",
      },
      show: {
        password: false,
        repita_password: false,
      },
    };
  },

  methods: {
    async changePassword() {
      this.salvando = true;
      this.custom_errors = {
        password: "",
        repita_password: "",
      };

      if (this.$refs.form.validate()) {
        axios
          .post(this.change_password_endpoint, this.recuperar)
          .then((res) => {
            this.$swal({
              titleText: "Senha alterada!",
              html: res.data.message,
              type: "success",
            }).then(() => {
              location.href = this.login_url;
            });
          })
          .catch((err) => {
            let errors = err?.response?.data?.errors;
            if (errors) {
              for (const key in errors) {
                if (Object.hasOwnProperty.call(errors, key)) {
                  this.custom_errors[key] = errors[key];
                }
              }
            }
          });
      }
      this.salvando = false;
    },
  },
};
</script>


<style lang="scss" scoped>
.botao_senha {
  cursor: pointer;
  font-size: 18px;
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>