<template>
  <v-app>
    <v-stepper elevation="0" v-model="step">
      <v-stepper-items>
        <!-- Metodos de pagamento -->
        <v-stepper-content step="1">
          <div
            class="ui-title--payment_subtitle method--title position-absolute"
          >
            <span> Selecione a forma de pagamento: </span>
          </div>
          <v-row class="p-2 method--row">
            <div class="d-flex justify-content-center col-6">
              <div
                class="payment-method-box d-flex p-3"
                @click="payment_method = 1"
                :class="payment_method == 1 ? 'selected-payment-method' : ''"
              >
                <v-img contain src="img/website/logo_paysell.svg"></v-img>
              </div>
            </div>
            <div class="d-flex justify-content-center col-6">
              <div
                class="payment-method-box d-flex p-3"
                @click="payment_method = 2"
                :class="payment_method == 2 ? 'selected-payment-method' : ''"
              >
                <v-img contain src="img/website/logo_pix.svg"></v-img>
              </div>
            </div>
            <div class="col-6 text-center method--subtitle">
              Cartão de crédito
            </div>
            <div class="col-6 text-center method--subtitle">PIX</div>
          </v-row>
          <div
            v-if="step_error"
            class="alert alert-danger text-center mb-0 mt-2"
            role="alert"
          >
            Selecione um método de pagamento
          </div>
          <button
            class="ui-button-green fundo_secundaria mt-3 py-sm-1 py-3"
            @click="ChooseMethod"
          >
            Continuar
          </button>
        </v-stepper-content>

        <!-- Cartão de crédito -->
        <v-stepper-content step="2">
          <div class="row align-items-center">
            <div class="col-12">
              <div class="row method--logo">
                <div class="col-md-2 col-4">
                  <v-img contain src="img/website/logo_paysell.svg"></v-img>
                </div>
                <div class="col-md-10 col-8 method--subtitle--intern">
                  Cartão de crédito
                </div>
              </div>
            </div>
          </div>
          <v-form ref="credit" class="row">
            <div class="col-md-6 col-12 pb-0">
              <v-text-field
                v-model="card.holder_name"
                type="text"
                label="Titular do Cartão*"
                :rules="[rules.required, rules.letters]"
                :error-messages="custom_errors.holder_name"
                outlined
                :disabled="salvando"
                :loading="salvando"
              ></v-text-field>
            </div>
            <div class="col-md-6 col-12 pb-0">
              <v-text-field
                v-model="card.card_number"
                type="text"
                label="Número do cartão*"
                v-mask="['#### #### #### ####']"
                :rules="[rules.required]"
                :error-messages="custom_errors.card_number"
                outlined
                :disabled="salvando"
                :loading="salvando"
                validate-on-blur
              ></v-text-field>
            </div>
            <div class="col-4 pb-0 cc-selects">
              <v-select
                v-model="card.expiration_month"
                label="Mês*"
                :items="months"
                :rules="[rules.required]"
                :error-messages="custom_errors.expiration_month"
                outlined
                :disabled="salvando"
                :loading="salvando"
              ></v-select>
            </div>
            <div class="col-4 pb-0 cc-selects">
              <v-select
                v-model="card.expiration_year"
                label="Ano*"
                :items="years"
                :rules="[rules.required]"
                :error-messages="custom_errors.expiration_year"
                outlined
                :disabled="salvando"
                :loading="salvando"
              ></v-select>
            </div>
            <div class="col-4 pb-0 cc-selects">
              <v-text-field
                v-model="card.security_code"
                type="text"
                label="CVV*"
                v-mask="['####']"
                :rules="[rules.required]"
                :error-messages="custom_errors.security_code"
                outlined
                :disabled="salvando"
                :loading="salvando"
              ></v-text-field>
            </div>
          </v-form>
          <div
            v-if="credit_error"
            class="alert alert-danger text-center mb-0 mt-2"
            role="alert"
          >
            {{ credit_error }}
          </div>
          <button
            class="ui-button-green fundo_secundaria mt-3 py-sm-1 py-3"
            :disabled="salvando"
            @click="makeCreditPayment"
          >
            <i v-if="salvando" class="fa fa-spinner fa-spin"></i>
            <span v-else> Continuar </span>
          </button>
          <button
            :disabled="salvando"
            class="ui-button-gray mt-3 py-sm-1 py-3"
            @click="step = 1"
          >
            Voltar
          </button>
        </v-stepper-content>

        <!-- Cartão de crédito -- Concluído -->
        <v-stepper-content step="3">
          <div class="row justify-content-center text-center">
            <div class="col-12 success-message">
              Pagamento realizado com sucesso!
            </div>
            <div class="success-animation col-12">
              <lottie-animation
                :loop="false"
                :autoPlay="play_animation"
                :key="play_animation"
                path="img/website/payment_success.json"
              />
            </div>
          </div>
          <button
            class="ui-button my-orders-button py-sm-1 py-3 fundo_secundaria"
            @click="Orders()"
          >
            Meus Pedidos
          </button>
        </v-stepper-content>

        <!-- PIX -->
        <v-stepper-content step="4">
          <div class="row align-items-center">
            <div class="col-md-6 col-12">
              <div class="row method--logo">
                <div class="col-4">
                  <v-img contain src="img/website/logo_pix.svg"></v-img>
                </div>
                <div class="col-2 method--subtitle--intern">PIX</div>
              </div>
              <div class="method--description">
                <span>Instruções:</span>
                <div>
                  1 - Leia ou copie o código do QRCODE PIX.<br />
                  2 - No banco de sua preferência efetue o pagamento.<br />
                  3 - Na tela “Meus pedidos”, aguarde a confirmação do
                  pagamento.
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="col-12 d-flex justify-content-center pb-0">
                <div
                  v-html="payment_response.qrcode64"
                  @click="CopyQRCODE"
                  class="method--QRCODE cursor-pointer"
                ></div>
              </div>
              <div class="col-12 text-center pt-0 method--QRCODE--subtitle">
                Clique no QRCODE para copiá-lo
                <v-icon>mdi mdi-content-copy</v-icon>
              </div>
            </div>
          </div>
          <button
            class="ui-button my-orders-button py-sm-1 py-3 fundo_secundaria"
            @click="Orders()"
          >
            Meus Pedidos
          </button>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-app>
</template>

<script>
import axios from "axios";
import VueTheMask from "vue-the-mask";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
export default {
  components: {
    LottieAnimation,
  },
  props: ["cartData", "orders_url", "pix_endpoint", "credit_endpoint"],
  data() {
    return {
      cart: {},
      step: 1, //voltar pro 1 depois
      step_error: false,
      credit_error: "",
      payment_method: null,
      payment_response: {
        qrcode64: "",
        qrcode_link: "",
      },
      rules: {
        required: (value) => !!value || "Obrigatório.",
        letters: (value) => /^[a-zA-Z\s]*$/.test(value) || "Apenas letras.",
      },
      custom_errors: {
        holder_name: "",
        card_number: "",
        expiration_month: "",
        expiration_year: "",
        security_code: "",
      },
      card: {
        holder_name: "",
        card_number: "",
        expiration_month: "",
        expiration_year: "",
        security_code: "",
      },
      salvando: false,
      play_animation: false,
    };
  },

  created() {
    this.cart = JSON.parse(this.cartData);
  },

  computed: {
    months() {
      let months = [];
      for (let index = 1; index <= 12; index++) {
        months.push(index);
      }

      return months;
    },

    years() {
      let current_year = new Date().getFullYear();
      let years = [];
      for (let index = current_year; index <= current_year + 20; index++) {
        years.push(index);
      }

      return years;
    },
  },

  methods: {
    ChooseMethod() {
      this.step_error = false;
      switch (this.payment_method) {
        case 1:
          this.step = 2;
          break;
        case 2:
          this.$swal({
            title:
              "<span style='line-height:normal'>Continuar para pagamento?</span>",
            html: "Ao continuar <b style='color:#ff5252'>não será possível alterar o método de pagamento!</b> Deseja continuar?",
            type: "warning",
            showCancelButton: true,
            allowOutsideClick: false,
            cancelButtonText: "Não!",
            cancelButtonColor: "primary",
            confirmButtonText: "Sim, continuar!",
          }).then((result) => {
            if (result.value) {
              this.MakePixPayment();
            }
          });
          break;

        default:
          this.step_error = true;
          break;
      }
    },
    async makeCreditPayment() {
      this.credit_error = "";
      this.salvando = true;
      if (this.$refs.credit.validate()) {
        await axios
          .post(
            this.credit_endpoint,
            { id: this.cart.id, card: this.card },
            { withCredentials: true }
          )
          .then((res) => {
            this.step = 3;
            this.play_animation = true;
          })
          .catch((err) => {
            this.credit_error = err?.response?.data?.message;
          });
      }
      this.salvando = false;
    },

    async MakePixPayment() {
      this.$swal({
        title:
          "<span style='line-height:normal'>Gerando QRCODE, aguarde...</span>",
        allowOutsideClick: false,
        onOpen: () => {
          this.$swal.showLoading();
        },
      });

      await axios
        .post(
          this.pix_endpoint,
          { id: this.cart.id },
          { withCredentials: true }
        )
        .then((res) => {
          this.payment_response = {
            qrcode64: res.data.qrcode,
            qrcode_link: res.data.link,
          };
          this.step = 4;
        })
        .catch((err) => {
          console.error(err);
        });

      this.$swal.close();
    },

    CopyQRCODE() {
      navigator.clipboard.writeText(this.payment_response.qrcode_link);
      Toast.fire("Link Copiado!", "", "success");
    },

    Orders() {
      location.href = this.orders_url;
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-method-box {
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  cursor: pointer;
  height: 100%;
  max-width: 350px;
  width: 100%;
  border: 3px solid transparent;
  transition: all 0.3s;
}

.selected-payment-method {
  border-color: #269300;
  transition: all 0.3s;
}

.method {
  &--title {
    left: 1px;
  }

  &--row {
    margin-top: 41px;
  }

  &--subtitle {
    padding-top: unset;
    font-weight: 300;
    line-height: 15px;
    color: #4a4a4a;
    font-size: 16px;
    margin-bottom: 22px;
    @media screen and (max-width: 991px) {
      font-size: 13px;
    }

    &--intern {
      @extend .method--subtitle;
      padding-top: 12px;
      padding-left: unset;
      margin-bottom: unset;
      align-self: center;
    }
  }

  &--description {
    margin-top: 35px;
    color: #4a4a4a;
    & span:first-child {
      @extend .method--description;
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
    }

    & div {
      margin-top: 11px;
      font-weight: 300;
      font-size: 17px;
      line-height: 34px;
    }
  }

  &--QRCODE {
    max-height: 350px;
    max-width: 350px;
    @media screen and (max-width: 425px) {
      max-height: 250px;
      max-width: 250px;
    }

    &--subtitle {
      font-weight: 300;
      font-size: 17px;
      line-height: 34px;
    }
  }
}

.my-orders-button {
  background-color: #ff993a;
  border-radius: 8px;
  color: white;
  font-size: 18px;
  font-weight: 700;
  padding: 5px 15px;
  width: 100%;
  margin-top: 40px;
}

.cc-selects {
  @media screen and (max-width: 376px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.success-message {
  font-size: 20px;
  font-weight: 700;
  line-height: 21px;
  margin-bottom: 30px;
  @media screen and (max-width: 425px) {
    font-size: 18px;
  }
}
.success-animation {
  max-height: 250px;
  max-width: 250px;
  @media screen and (max-width: 425px) {
    max-height: 225px;
    max-width: 225px;
  }
}

::v-deep .v-stepper__content {
  padding-inline: unset;
}

::v-deep .v-application--wrap {
  min-height: fit-content !important;
}
</style>