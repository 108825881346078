<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-12 col-lg-6 col-md-8">
        <div class="text-left text-md-center mb-2 mt-2">
          <img :src="item.image" :alt="item.name" />
        </div>
        <h1 class="ui-title text-left text-md-center">
          {{ item.name }}
        </h1>
        <div class="ui-text" v-html="item.description"></div>
        <div class="ui-text mt-2 text-center mb-2 cursor-pointer" @click="show = !show">
          <b> Adicionar observação <i class="fa fa-edit ml-1 mt-1"></i></b>
        </div>
        <div class="observacao_area" :class="show ? 'aparece' : 'oculta'">
          <textarea
            placeholder="Observação:"
            type="textarea"
            rows="8"
            v-model="observation"
            class="ui-input text_input py-2 borda-primaria"
          ></textarea>
        </div>
        <div
          v-if="item.promotion_value > 0"
          class="ui-promocao text-center mt-3"
        >
          <s>De {{ FormatValue(item.price) }}</s>
          <br />
          <b class="ui-subtitle cor_primaria text-center">
            Por {{ FormatValue(item.promotion_value) }}
          </b>
        </div>
        <div v-else class="text-center mt-3">
          <b class="ui-subtitle cor_primaria text-center">
            {{ FormatValue(item.price) }}
          </b>
        </div>

        <button
          @click="SendOrder"
          :disabled="item.quantity == 0"
          class="ui-button-green fundo_secundaria my-2"
          :class="item.quantity == 0 ? 'ui-button-gray' : ''"
        >
          <span v-if="item.quantity == 0">Sem estoque</span>
          <i v-else-if="enviando" class="fa fa-spinner fa-spin"></i>
          <span v-else> Adicionar ao carrinho </span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
const currency = require("currency.js");
export default {
  props: ["product", "link", "add_product_endpoint"],
  data() {
    return {
      item: {},
      show: false,
      enviando: false,
      observation: "",
    };
  },

  created() {
    this.item = JSON.parse(this.product);
  },

  methods: {
    async SendOrder() {
      this.enviando = true;
      let orderList = await this.orderPayload();

      if (orderList.length > 0) {
        await axios
          .post(
            this.add_product_endpoint,
            { order: orderList, link: this.link },
            { withCredentials: true }
          )
          .then((res) => {
            this.$swal({
              titleText: "Produto adicionado ao carrinho!",
              type: "success",
              timer: 1200,
            }).then(() => {
              location.reload();
            });
          })
          .catch((err) => {
            console.error(err);
          });
      }
      this.enviando = false;
    },

    async orderPayload() {
      let order = [];
      order.push({
        productID: this.item.id,
        quantity: 1,
        value: this.item.promotion_value || this.item.price,
        observation: this.observation || "",
      });

      return order;
    },

    FormatValue(value) {
      const price = Number(value);
      return currency(price, {
        separator: ".",
        decimal: ",",
        symbol: "R$",
      }).format();
    },
  },
};
</script>

<style lang="scss" scoped>
.aparece {
  height: auto;
  opacity: 1;
  transition: all 0.3s;
  visibility: visible;
}
.oculta {
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
</style>