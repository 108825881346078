<template>
  <div>
    <div class="ui-lista overflow_carrinho mt-2">
      <ul class="row">
        <li class="col-md-6 col-12" v-for="(item, index) in lista" :key="index">
          <div class="ui-lista-area">
            <div class="row">
              <div class="col-6 pl-0" v-if="item.image">
                <a :href="/produto/ + item.slug">
                  <div
                    class="ui-show-image-area"
                    :style="'background-image: url(' + item.image + ')'"
                  >
                    <img :src="item.image" :alt="item.name" />
                  </div>
                </a>
              </div>
              <div :class="item.image ? 'col-6 pl-0' : 'col-12 pl-2'">
                <div
                  class="ui-lista-conteudo"
                  :class="item.image ? '' : 'pl-3'"
                >
                  <div class="ui-lista-titulo text-left">
                    {{ item.name }}
                  </div>
                  <div class="ui-lista-quantidade-row">
                    <div class="ui-lista-quantidade text-left mt-2">
                      <button
                        :disabled="enviando"
                        v-if="item.quantity !== null"
                        @click="
                          item.quantity === 0 ? (item.quantity = 0) : Sub(item)
                        "
                      >
                        <i class="fa fa-minus"></i>
                      </button>
                      <span
                        class="text-danger"
                        style="font-size: 1.5rem"
                        v-if="item.quantity === null"
                        >Sem estoque</span
                      >
                      <b v-else>
                        {{ item.quantity }}
                      </b>
                      <button
                        :disabled="enviando"
                        v-if="item.quantity !== null"
                        @click="
                          item.quantity < item.max_quantity ? Sum(item) : ''
                        "
                      >
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>
                    <div
                      v-if="item.promotion_value"
                      class="ui-promocao text-center mt-3"
                    >
                      <s>De {{ FormatValue(item.price) }}</s>
                      <br />
                      <b class="ui-subtitle cor_primaria text-center">
                        Por {{ FormatValue(item.promotion_value) }}
                      </b>
                    </div>
                    <div v-else class="ui-lista-valor text-left cor_primaria">
                      {{ FormatValue(item.price) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="total cor_primaria">
      <div>Total</div>
      <div>{{ FormatValue(total) }}</div>
    </div>
    <div class="enviar">
      <div v-if="vazio" class="alert alert-danger text-center" role="alert">
        Adicione quantidade a algum dos produtos acima
      </div>
      <button
        @click="SendOrder"
        :disabled="enviando"
        class="ui-button-green fundo_secundaria"
      >
        <i v-if="enviando" class="fa fa-spinner fa-spin"></i>
        <span v-else> Enviar pedido </span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const currency = require("currency.js");
export default {
  props: ["productsList", "link", "add_product_endpoint", "link", "check_user"],
  data() {
    return {
      lista: [],
      total: 0,
      enviando: false,
      vazio: false,
    };
  },
  created() {
    this.lista = JSON.parse(this.productsList);
  },

  methods: {
    async SendOrder() {
      this.enviando = true;
      this.vazio = false;
      let orderList = await this.orderPayload();

      if (orderList.length > 0) {
        if (!(await this.isLogged())) {
          localStorage.setItem(
            "products",
            JSON.stringify({ order: orderList, link: this.link })
          );
          await this.$swal({
            title: "Só falta o login",
            text: "Para continuar, você precisar logar na sua conta. Iremos direcionar você para a tela de login e aí você poderá avançar com suas compras :)",
            type: "success",
          }).then((value) => {
            if (value.value) {
              location.href = "/login";
            }
          });
        }
        await axios
          .post(
            this.add_product_endpoint,
            { order: orderList, link: this.link },
            { withCredentials: true }
          )
          .then((res) => {
            this.$swal({
              titleText: "Produtos adicionados ao carrinho!",
              type: "success",
              timer: 1200,
            }).then(() => {
              location.reload();
            });
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        this.vazio = true;
      }
      this.enviando = false;
    },

    async orderPayload() {
      let order = [];
      this.lista.forEach((item) => {
        if (item.quantity > 0) {
          order.push({
            productID: item.id,
            quantity: item.quantity,
            value: item.promotion_value || item.price,
            observation: "",
          });
        }
      });

      return order;
    },

    Sum(item) {
      item.quantity++;
      this.total += Number(item.promotion_value) || Number(item.price);
    },

    Sub(item) {
      item.quantity--;
      this.total -= Number(item.promotion_value) || Number(item.price);
    },

    FormatValue(value) {
      const price = Number(value);
      return currency(price, {
        separator: ".",
        decimal: ",",
        symbol: "R$",
      }).format();
    },

    async isLogged() {
      let is_logged = true;
      await axios
        .get(this.check_user)
        .then((response) => {
          if (!("email" in response.data)) {
            is_logged = false;
          }
        })
        .catch((error) => {
          if (error.data) {
            is_logged = false;
          }
        });
      return is_logged;
    },
  },
};
</script>

<style lang="scss" scoped>
.total {
  align-items: center;
  border-top: 1px #d9d9d9 solid;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-top: 10px;
}

.blank-error-message {
  color: #ff5252 !important;
}
</style>
