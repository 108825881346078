<template>
  <v-app>
    <v-container>
      <v-form ref="form" lazy-validation v-model="valid">
        <div class="row">
          <div class="col-md-6 col-12 pb-0">
            <v-text-field
              v-model="cadastro.nome"
              label="Nome completo*"
              :rules="[rules.required]"
              :error-messages="custom_errors.nome"
              outlined
              :disabled="salvando"
              :loading="salvando"
            ></v-text-field>
          </div>
          <div class="col-md-6 col-12 pb-0">
            <v-text-field
              v-model="cadastro.email"
              type="email"
              label="E-mail*"
              :rules="[rules.required]"
              :error-messages="custom_errors.email"
              outlined
              :disabled="salvando"
              :loading="salvando"
            ></v-text-field>
          </div>
          <div class="col-md-6 col-12 pb-0">
            <v-text-field
              @click:append="show1 = !show1"
              :type="show1 ? 'text' : 'password'"
              :append-icon="show ? 'fa-eye-slash' : 'fa-eye'"
              v-model="cadastro.password"
              label="Senha*"
              :rules="!user_data ? [rules.required, rules.min] : []"
              :error-messages="custom_errors.password"
              outlined
              :disabled="salvando"
              :loading="salvando"
            ></v-text-field>
          </div>
          <div class="col-md-6 col-12 pb-0">
            <v-text-field
              @click:append="show2 = !show2"
              :type="show2 ? 'text' : 'password'"
              :append-icon="show ? 'fa-eye-slash' : 'fa-eye'"
              v-model="cadastro.repita_password"
              label="Repita Senha*"
              :rules="
                !user_data || (user_data && cadastro.password)
                  ? [rules.required, rules.equal]
                  : []
              "
              :error-messages="custom_errors.repita_password"
              outlined
              :disabled="salvando"
              :loading="salvando"
            ></v-text-field>
          </div>
          <div class="col-md-6 col-12 pb-0">
            <v-text-field-simplemask
              v-model="cadastro.CPF"
              label="CPF*"
              v-bind:properties="{
                disabled: salvando,
                outlined: true,
                clearable: true,
                loading: salvando,
                placeholder: '',
                rules: [rules.required],
                'error-messages': custom_errors.CPF,
              }"
              v-bind:options="{
                inputMask: '###.###.###-##',
                outputMask: '###.###.###-##',
                empty: null,
                applyAfter: false,
                alphanumeric: true,
                lowerCase: false,
              }"
            />
          </div>
          <div class="col-md-6 col-12 pb-0">
            <v-text-field-simplemask
              v-model="cadastro.telefone"
              label="Telefone*"
              v-bind:properties="{
                disabled: salvando,
                outlined: true,
                clearable: true,
                loading: salvando,
                placeholder: '',
                rules: [rules.required],
                'error-messages': custom_errors.telefone,
              }"
              v-bind:options="{
                inputMask: '(##) #####-####',
                outputMask: '(##) #####-####',
                empty: null,
                applyAfter: false,
                alphanumeric: true,
                lowerCase: false,
              }"
            />
          </div>
          <div class="col-12 p-0"></div>
          <div class="col-md-6 col-12 pb-0">
            <v-text-field-cep
              v-model="cadastro.cep"
              label="CEP*"
              @change="searchCep(cadastro.cep)"
              v-bind:properties="{
                disabled: loadingCep || salvando,
                outlined: true,
                clearable: true,
                loading: loadingCep || salvando,
                placeholder: '',
                rules: [rules.required],
                'error-messages': custom_errors.cep,
              }"
              v-bind:options="{
                outputMask: '#####-###',
                empty: null,
                applyAfter: false,
              }"
            />
          </div>
          <div class="col-md-6 col-12 pb-0">
            <v-autocomplete
              v-model="cadastro.estado"
              autocomplete="off"
              :items="estados"
              :rules="[rules.required]"
              :error-messages="custom_errors.estado"
              :loading="salvando || loadingEstados || loadingCep"
              :disabled="salvando || loadingEstados || loadingCep"
              label="Estado*"
              append-icon="fa fa-caret-down"
              outlined
              @change="filterCidade(cadastro.estado)"
            ></v-autocomplete>
          </div>
          <div class="col-md-6 col-12 pb-0">
            <v-autocomplete
              autocomplete="off"
              v-model="cadastro.cidade"
              :items="cidadesFiltradas"
              :loading="salvando || loadingCidades || loadingCep"
              :disabled="salvando || loadingCidades || loadingCep"
              label="Cidade*"
              :rules="[rules.required]"
              :error-messages="custom_errors.cidade"
              append-icon="fa fa-caret-down"
              outlined
            ></v-autocomplete>
          </div>
          <div class="col-md-6 col-12 pb-0">
            <v-text-field
              v-model="cadastro.rua"
              label="Rua*"
              :rules="[rules.required]"
              :error-messages="custom_errors.rua"
              outlined
              :disabled="salvando"
              :loading="salvando"
            ></v-text-field>
          </div>
          <div class="col-md-6 col-12 pb-0">
            <v-text-field
              v-model="cadastro.bairro"
              label="Bairro*"
              :rules="[rules.required]"
              :error-messages="custom_errors.bairro"
              outlined
              :disabled="salvando"
              :loading="salvando"
            ></v-text-field>
          </div>
          <div class="col-md-6 col-12 pb-0">
            <v-text-field
              v-model="cadastro.numero"
              label="Número*"
              :rules="[rules.required]"
              :error-messages="custom_errors.numero"
              outlined
              :disabled="salvando"
              :loading="salvando"
            ></v-text-field>
          </div>
          <div class="col-md-6 col-12 pb-0">
            <v-text-field
              v-model="cadastro.complemento"
              label="Complemento"
              outlined
              :error-messages="custom_errors.complemento"
              :disabled="salvando"
              :loading="salvando"
            ></v-text-field>
          </div>
        </div>
        <button
          v-if="!user_data"
          class="ui-button fundo_secundaria w-100 mt-3"
          @click="newUser"
          :disabled="salvando"
          type="button"
        >
          <i v-if="salvando" class="fa fa-spinner fa-spin"></i>
          <span v-else> Cadastrar </span>
        </button>
        <button
          v-else
          class="ui-button fundo_secundaria w-100 mt-3"
          @click="alterProfile"
          type="button"
          :disabled="salvando"
        >
          <i v-if="salvando" class="fa fa-spinner fa-spin"></i>
          <span v-else> Salvar alterações </span>
        </button>
      </v-form>
      <div v-if="!user_data">
        <a href="/login">
          <div class="ui-text cor_primaria mt-4 text-center">
            Já tem uma conta? voltar ao login
          </div>
        </a>
        <a href="/recuperar-senha">
          <div class="ui-text mt-3 text-center">
            Perdeu a senha? Recuperar senha
          </div>
        </a>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import axios from "axios";
import VuetifyMask from "vuetify-mask";
Vue.use(VuetifyMask);
export default {
  props: [
    "user_data",
    "change_profile_endpoint",
    "new_user_endpoint",
    "home_url",
    "add_product_endpoint",
  ],
  data() {
    return {
      valid: true,
      salvando: true,
      loadingCep: false,
      estados: [],
      cidades: [],
      show1: false,
      show2: false,
      cidadesFiltradas: [],
      loadingEstados: false,
      loadingCidades: false,
      rules: {
        required: (value) => !!value || "Obrigatório.",
        min: (v) => v.length >= 6 || "Mínimo 6 caracteres",
        equal: (v) =>
          v == this.cadastro.password || "As senhas devem ser iguais.",
      },
      cadastro: {
        nome: "",
        email: "",
        password: "",
        repita_password: "",
        CPF: "",
        telefone: "",
        cep: "",
        rua: "",
        bairro: "",
        numero: "",
        cidade: "",
        estado: "",
        complemento: "",
      },
      custom_errors: {
        nome: "",
        email: "",
        CPF: "",
        password: "",
        repita_password: "",
        telefone: "",
        cep: "",
        rua: "",
        bairro: "",
        numero: "",
        cidade: "",
        estado: "",
        complemento: "",
      },
    };
  },

  methods: {
    async loadContent() {
      await this.getEstados();
      await this.getCidade();
    },

    searchCep(cep = "") {
      if (cep.length === 9) {
        this.loadingCep = true;
        axios
          .get("https://viacep.com.br/ws/" + cep + "/json/")
          .then((response) => {
            if (!response.data.erro) {
              this.cadastro.rua = response.data.logradouro;
              this.cadastro.bairro = response.data.bairro;
              this.cadastro.complemento = response.data.complemento;
              if (response.data.uf) {
                this.cadastro.estado = response.data.uf;
                this.cadastro.cidade = response.data.localidade;
                this.filterCidade(response.data.uf);
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.$root.SnackControl.open(this, {
              text: "Erro ao buscar por CEP!",
              color: "error",
            });
          })
          .finally(() => {
            this.loadingCep = false;
          });
      }
      this.salvando = false;
    },
    async getEstados() {
      this.loadingEstados = true;
      await axios
        .get(
          "https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome"
        )
        .then((response) => {
          for (let estado = 0; estado < response.data.length; estado++) {
            this.estados.push({
              text: response.data[estado].nome,
              value: response.data[estado].sigla,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$root.SnackControl.open(this, {
            text: "Erro ao buscar os estados!",
            color: "error",
          });
        });
      this.loadingEstados = false;
    },
    async getCidade() {
      this.loadingCidades = true;
      await axios
        .get("https://servicodados.ibge.gov.br/api/v1/localidades/municipios")
        .then((response) => {
          for (let cidade = 0; cidade < response.data.length; cidade++) {
            this.cidades.push({
              text: response.data[cidade].nome,
              value: response.data[cidade].nome,
              uf: response.data[cidade].microrregiao.mesorregiao.UF.sigla,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$root.SnackControl.open(this, {
            text: "Erro ao buscar as cidades!",
            color: "error",
          });
        });
      this.loadingCidades = false;
      this.salvando = false;
    },
    filterCidade(estado) {
      this.cidadesFiltradas = this.cidades.filter((city) => city.uf === estado);
    },
    resetCustomErrors() {
      this.custom_errors = {
        nome: "",
        email: "",
        password: "",
        repita_password: "",
        telefone: "",
        cep: "",
        rua: "",
        bairro: "",
        numero: "",
        cidade: "",
        estado: "",
        complemento: "",
      };
    },
    async putProductsOnCart() {
      const products = JSON.parse(localStorage.getItem("products"));
      await axios.post(this.add_product_endpoint, products, {
        withCredentials: true,
      });
      localStorage.removeItem("products");
    },
    async newUser() {
      this.resetCustomErrors();
      if (this.$refs.form.validate()) {
        this.salvando = true;
        await axios
          .post(this.new_user_endpoint, this.cadastro, {
            withCredentials: true,
          })
          .then(async (res) => {
            document.cookie = `FrontJwt=${res.data.token};`;
            await this.putProductsOnCart();
            location.href = this.home_url;
          })
          .catch((err) => {
            let errors = err?.response?.data?.errors;
            if (errors) {
              for (const key in errors) {
                if (Object.hasOwnProperty.call(errors, key)) {
                  this.custom_errors[key] = errors[key];
                }
              }
            }
          });
      }
      this.salvando = false;
    },

    async alterProfile() {
      this.resetCustomErrors();
      if (this.$refs.form.validate()) {
        this.salvando = true;
        await axios
          .post(this.change_profile_endpoint, this.cadastro, {
            withCredentials: true,
          })
          .then((res) => {
            console.log(res);
            this.$swal({
              titleText: res.data.message,
              type: "success",
              timer: 1200,
            }).then(() => {
              (this.cadastro.password = ""),
                (this.cadastro.repita_password = "");
            });
          })
          .catch((err) => {
            let errors = err?.response?.data?.errors;
            if (errors) {
              for (const key in errors) {
                if (Object.hasOwnProperty.call(errors, key)) {
                  this.custom_errors[key] = errors[key];
                }
              }
            }
          });
      }
      this.salvando = false;
    },
  },
  async created() {
    await this.loadContent();
    if (this.user_data) {
      let user = JSON.parse(this.user_data);
      this.cadastro = {
        nome: user.name,
        email: user.email,
        password: "",
        repita_password: "",
        CPF: user.CPF,
        telefone: user.phone,
        cep: user.CEP,
        rua: user.street,
        bairro: user.district,
        numero: user.number,
        cidade: user.city,
        estado: user.state,
        complemento: user.complement,
      };
      this.filterCidade(this.cadastro.estado);
    }
  },
};
</script>


<style lang="scss" scoped>
.botao_password {
  cursor: pointer;
  font-size: 18px;
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>